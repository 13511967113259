@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@import './prescription-labs-scans', './colors', './charts.scss';

html,
body {
  background-color: var(--body-color);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
}

body * {
  font-family: Montserrat;
}

#root {
  height: 100%;
}

#filter-box {
  display: inline;
  width: auto;
  margin: 0;
}

.multi-select {
  --rmsc-p: 5px !important;
}
option {
  color: black !important;
}

#filterSection {
  justify-content: flex-end;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 0em;
}

button:disabled {
  background-color: var(--greyish) !important;
  border: none;
  color: white !important;
  svg {
    display: none;
  }
}

.CircularProgressbar-text {
  font-weight: 800;
}

.navbar .navbar-nav a.active {
  background-color: var(--pale-lilac);
}

.table thead th {
  font-size: 14px;
}

.highlighted {
  background: rgba(237, 102, 99, 0.4);
}

.entitySelectSpinner {
  padding: 32px;
}

.Toastify__toast--info {
  background: #fff !important;
  color: #212529;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.4), 0 2px 15px 0 rgba(0, 0, 0, 0.1) !important;
  padding: 11px !important;
}

.redLabel::after {
  position: absolute;
  top: 12px;
  right: 7px;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: var(--grapefruit);
}

td {
  vertical-align: middle !important;
}

#medication-search .tooltip-inner {
  max-width: 400px;
}

.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  h1 {
    color: red;
    font-size: 15em;
  }

  p {
    font-size: 1.5em;
  }

  a {
    font-size: 1.1em;
  }
}
#notification-dd {
  color: rgba(255, 255, 255, 0.35);
}
ul{
  list-style-type: none;
}
.nav-pills{
  background: white;
  box-shadow: 0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A;
}

@media only screen and (max-width: 1700px) {
  #content_res {
    margin-left: 1px;
    max-width: 100%;
    flex: 1;
  }
}
@media only screen and (min-width: 1700px) {
  #closeIcon_res {
    display: none;
  }
}

.assign_dialog {
  position: absolute;
  top: 0;
  left: 50%;
  width: 60rem;
  transform: translate(-50%);
}
