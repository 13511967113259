.sidebarNav {
  width: 100%;

  a {
    margin-top: 0.8rem;
    &:hover {
      background-color: var(--pale-lilac);
    }

    svg {
      width: 1.5rem;
      margin-bottom: 0.2rem;
    }
    span {
      font-size: 14px;
      padding-left: 1rem;
    }
  }
}

.brand {
  padding: 0;
  margin: 0;

  img {
    width: 100%;
    margin-top: -1.8rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
  }
}

.StickyNav {
  position: static ;

  path {
    fill: black;
  }

  .closeIcon {
    position: absolute;
    top: 3;
    left: 15px;
    cursor: pointer;
  }
}
