.accessDeniedIcon {
  display: block;
  width: 165px;
  margin: 0 auto;
  padding-left: 28px;
}

.accessDeniedCode {
  font-size: 64px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--violet-blue);
}

.accessDeniedTitle {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.accessDeniedDesc {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--greyish);
}

.accessDeniedBtnCont {
  text-align: center;
}

.accessDeniedBtn {
  border-radius: 5px;
  background-color: var(--violet-blue);
  border: none;
}
