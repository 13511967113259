.hBar {
  display: inline-block;
  width: 20%;
  padding-right: 15px;
}

.formfield {
  margin-bottom: 20px;
}

.rowSpacing {
  margin-top: 25px;
  margin-bottom: 25px;
}
