.filterResearchBtn {
  margin-top: 29px;
  background: var(--pale-grey);
  border: solid 1px var(--white-three);
  height: 41px;
}

.filterResearchBox {
  &.hidden {
    display: none;
  }

  display: block;
  position: absolute;
  right: 16px;
  top: 140px;
  width: 300px;
  margin: 11px 2px 23px 264px;
  padding: 12px 2px 16.4px 19px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-two);
  z-index: 1;

  h2 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  label {
    margin-left: 5px;
    margin-bottom: 14px;
    font-size: 18px;
  }
  .clearFilterResearchBtn {
    background: var(--pale-grey);
    border: none;
    color: black;
    width: 145px;
  }

  button {
    margin-right: 10px;
    border: none;
    min-width: 100px;
  }
}

.filtersActivated:after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  left: 47px;
  top: 27px;
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  background: var(--dark-peach);
}
