:root {
  --white: #f8f8f8;
  --violet-blue: #2e0fcd;
  --white-two: #ffffff;
  --pale-lilac: #f3f1ff;
  --dark: #1b262c;
  --greyish: #a8a8a8;
  --grapefruit: #fd5e53;
  --very-light-blue: #dce5ff;
  --dark-mint-green: #21bf73;
  --pale-grey: #f1f3f9;
  --light-teal: #abecec;
  --dark-peach: #ed6663;
  --white-three: #dadada;
  --indigo-blue: #2d179e;
  --light-royal-blue: #4b28fc;
  --dark-aquamarine: #027372;
  --turquoise: #02acab;
  --greeny-blue: #42c8c6;
  --sun-flower: #ecad00;
  --skin-color: #fde8e8;
  --blody-color: #e2e9ff;
  --header-color: #536dfe;
  --body-color: #f6f7ff;
}
