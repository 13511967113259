.dropdown.nav-item a::after {
  display: none;
}
div[aria-labelledby='notification-dd'].show {
  right: 0 !important;
  left: auto !important;
  min-width: 350px;
  padding-top: 0;
  z-index: 9999;
}

div[aria-labelledby='notification-dd'] p {
  white-space: normal;
  font-size: 14px;
}

.centeredLoader .spinner-border {
  display: block;
  margin: 0 auto;
}
