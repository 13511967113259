.mainNabBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 77px;
  background: var(--header-color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 3;
}
.ddbox > a {
  color: white !important;
}
.burger_icon {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}
.navbar {
  background: #fff;
}

.brand {
  padding-left: 1rem;
  img {
    cursor: pointer;
  }
}

.notificationHeader {
  display: inline-block;
  text-align: center;
  color: var(--violet-blue);
  background-color: var(--pale-grey);
  padding: 15px 10px;
  margin-bottom: 8px;
}

.notificationFooter {
  border-top: 1px solid #ddd;
  text-align: center;
  display: inline-block;
  padding-top: 8px;
}

.timeLabel {
  color: var(--greyish);
  font-size: 12px !important;
  margin-bottom: 0;
}

.inactiveText {
  width: 100px;
  position: relative;
  left: -23px;
  top: 4px;
  color: white;
  font-size: 13px;
}

.activeText {
  width: 100px;
  position: relative;
  right: -19px;
  top: 4px;
  color: white;
  font-size: 13px;
}

.ActiveContainer {
  display: flex;
  align-items: center;

  & > p {
    margin-left: 10px;
  }
}
